





































































import { Vue, Component } from "vue-property-decorator";
import Subscribers, { Subscriber } from "@/store/subscribers";
import FancyButton from "@/components/FancyButton.vue";

import * as R from "ramda";

@Component({
  components: {
    FancyButton
  }
})
export default class extends Vue {
  headers = [
    { text: "Type", value: "name" },
    { text: "Bought", value: "issuedAt" },
    { text: "Uses", value: "usageCount" },
    { text: "Active", value: "active" },
    { text: "Stripe ID", value: "transactionId" }
  ];

  subscriber = {} as Subscriber;

  error = "";
  isLoading = false;

  get id() {
    return this.$route.params.id;
  }

  get statusIcon() {
    if (this.subscriber.status as any === 'ACTIVE') {
      return 'mdi-check-circle-outline';
    } else if (this.subscriber.status as any === 'INACTIVE') {
      return 'mdi-close-circle-outline';
    } else if (this.subscriber.status as any === 'DELETED') {
      return 'mdi-delete-circle-outline'
    }
  }

  edit() {
      this.$router.push({ name: 'subscriber-edit', params: { id: this.id }});
  }

  async mounted() {
    this.isLoading = true;
    try {
      const subscriberResponse = await Subscribers.fetchOne({ id: this.id });
      const fetchedSubscriber = subscriberResponse.data as Subscriber;
      this.subscriber = R.clone(fetchedSubscriber);
    } catch (err) {
      this.error = err.message;
    }
    this.isLoading = false;
  }

  async remove() {
    const really = confirm("Do you really want to delete this subscriber?");
    if (!really) {
      return;
    }

    this.isLoading = true;
    await Subscribers.remove({ id: this.id });
    this.$router.push('/subscribers');
  }
}
