








import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class extends Vue {
  @Prop() to?: string;
  @Prop() text!: string;
  @Prop() icon!: string;
  @Prop({ default: true }) dark!: boolean;
  @Prop({ default: "primary" }) colorClass!: string;

  click() {
    this.$emit("click");
  }
}
